@use '@angular/material' as mat;
@import "normalize.css/normalize";

//THEMES
@import '@angular/material/theming';
@include mat.core();

.main-light-theme {
    $primary: mat.define-palette(mat.$indigo-palette);
    $accent: mat.define-palette(mat.$pink-palette);
    $theme: mat.define-light-theme($primary, $accent);
    @include mat.all-component-themes($theme);
    // SCROLLERS
    .mat-drawer-content::-webkit-scrollbar-track,
    .mat-drawer-inner-container::-webkit-scrollbar-track,
    .mat-dialog-content::-webkit-scrollbar-track,
    .mat-card-content::-webkit-scrollbar-track,
    .mat-select-panel::-webkit-scrollbar-track,
    .mat-tab-body-content::-webkit-scrollbar-track,
    .mat-autocomplete-panel::-webkit-scrollbar-track {
        background-color: transparent;
        border: none;
    }

    .mat-drawer-content::-webkit-scrollbar,
    .mat-drawer-inner-container::-webkit-scrollbar,
    .mat-dialog-content::-webkit-scrollbar,
    .mat-card-content::-webkit-scrollbar,
    .mat-select-panel::-webkit-scrollbar,
    .mat-tab-body-content::-webkit-scrollbar,
    .mat-autocomplete-panel::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    .mat-drawer-content::-webkit-scrollbar-thumb,
    .mat-drawer-inner-container::-webkit-scrollbar-thumb,
    .mat-dialog-content::-webkit-scrollbar-thumb,
    .mat-card-content::-webkit-scrollbar-thumb,
    .mat-select-panel::-webkit-scrollbar-thumb,
    .mat-tab-body-content::-webkit-scrollbar-thumb,
    .mat-autocomplete-panel::-webkit-scrollbar-thumb {
        background-color: mat.get-color-from-palette($primary);
        border: none;
    }

    .mat-subheader {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 300);
        color: mat.get-color-from-palette($accent) !important;
        height: auto !important;
        padding: 5px !important;
    }

    table.mat-table {
        .mat-row:hover {
            background-color: mat.get-color-from-palette(mat.$grey-palette, 100);
        }
    }


    .highlight-primary {
        background: mat.get-color-from-palette($primary, 100);
    }

    .highlight-accent {
        background: mat.get-color-from-palette($accent, 100);
    }

    .color-primary {
        color: mat.get-color-from-palette($primary);
    }

    .color-accent {
        color: mat.get-color-from-palette($accent);
    }
}


.main-dark-theme {
    $primary: mat.define-palette(mat.$cyan-palette);
    $accent: mat.define-palette(mat.$amber-palette);
    $theme: mat.define-dark-theme($primary, $accent);
    @include mat.all-component-themes($theme);
    // SCROLLERS
    .mat-drawer-content::-webkit-scrollbar-track,
    .mat-drawer-inner-container::-webkit-scrollbar-track,
    .mat-dialog-content::-webkit-scrollbar-track,
    .mat-card-content::-webkit-scrollbar-track,
    .mat-select-panel::-webkit-scrollbar-track,
    .mat-tab-body-content::-webkit-scrollbar-track,
    .mat-autocomplete-panel::-webkit-scrollbar-track {
        background-color: transparent;
        border: none;
    }

    .mat-drawer-content::-webkit-scrollbar,
    .mat-drawer-inner-container::-webkit-scrollbar,
    .mat-dialog-content::-webkit-scrollbar,
    .mat-card-content::-webkit-scrollbar,
    .mat-select-panel::-webkit-scrollbar,
    .mat-tab-body-content::-webkit-scrollbar,
    .mat-autocomplete-panel::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    .mat-drawer-content::-webkit-scrollbar-thumb,
    .mat-drawer-inner-container::-webkit-scrollbar-thumb,
    .mat-dialog-content::-webkit-scrollbar-thumb,
    .mat-card-content::-webkit-scrollbar-thumb,
    .mat-select-panel::-webkit-scrollbar-thumb,
    .mat-tab-body-content::-webkit-scrollbar-thumb,
    .mat-autocomplete-panel::-webkit-scrollbar-thumb {
        background-color: mat.get-color-from-palette($primary);
        border: none;
    }

    .mat-subheader {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 700);
        color: mat.get-color-from-palette($accent) !important;
        height: auto !important;
        padding: 5px !important;
    }

    table.mat-table {
        .mat-row:hover {
            background-color: mat.get-color-from-palette(mat.$grey-palette, 700);
        }
    }


    .highlight-primary {
        background: mat.get-color-from-palette($primary, 700);
    }

    .highlight-accent {
        background: mat.get-color-from-palette($accent, 700);
    }

    .color-primary {
        color: mat.get-color-from-palette($primary);
    }

    .color-accent {
        color: mat.get-color-from-palette($accent);
    }
}

// CARDS
.mat-card-header-text {
    min-width: 100%;
}

.mat-card-title {
    display: flex !important;
    flex: 1 1 auto;
    justify-content: space-between !important;
    vertical-align: bottom !important;
    padding-right: 1em;
}

.mat-card-content {
    overflow-x: auto;
}

// DRAWER
.mat-drawer-container {
    height: calc(100vh - 48px);
}

.mat-drawer-content {
    height: calc(100vh - 48px);
}

.mat-drawer {
    max-width: 350px;
}

// TOOLBAR

.mat-toolbar-row, .mat-toolbar-single-row {
    height: 48px !important;
}

// ELEVATION

.mat-elevation-z6, .mat-elevation-z2 {
    position: relative;
    z-index: 2;
}

// AUTOCOMPLETE

.cdk-overlay-pane {
    max-width: 50vw !important;
    width: auto !important;
}

// TABS
.mat-tab-label {
    min-width: 60px !important;
}

// TABLES

table.mat-table {
    width: 100%;

    .mat-header-cell {
        vertical-align: middle;
        padding-right: 0.5em;
    }

    .mat-header-row, .mat-row, .mat-footer-row {
        .mat-header-cell, .mat-cell, .mat-footer-cell {
            vertical-align: middle;
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }
}


// CUSTOM

a {
    text-decoration: none;
}

.spacer {
    flex: 1 1 auto;
}

.text-right {
    text-align: right !important;
}

.text-right .mat-sort-header-container {
    float: right;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.align-middle {
    vertical-align: middle;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.pt-1 {
    padding-top: 0.5rem;
}

.pt-2 {
    padding-top: 1rem;
}

.p-1 {
    padding: 0.5rem;
}

.button-row {
    display: flex;
    justify-content: flex-end;
}

.button-row > * {
    align-self: center;
}

.mat-dialog-content img, .proverka img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: calc(65vh - 48px);
}
